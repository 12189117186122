var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white--text mt-10",attrs:{"id":"mailchimp-subscribe"}},[_c('v-row',{staticClass:"white--text"},[_c('v-col',{staticClass:"mb-2 col-md-10 offset-md-1",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"d-flex flex-wrap px-2 mb-2",class:_vm.subscribeStylingResponsive,attrs:{"outlined":"","dark":""}},[_c('div',{},[_c('v-card-title',{staticClass:"body-2"},[_vm._v("Rimani aggiornato sulle novitá di Dear Mama")]),_c('v-card-subtitle',{staticClass:"caption"},[_vm._v("No spam, solo notizie estremamente rilevanti.")])],1),_c('v-card-actions',[_c('mailchimp-subscribe',{attrs:{"url":"https://dearmamafilm.us1.list-manage.com/subscribe/post-json","user-id":"8c358d4ae52a6ebf2de70ad84","list-id":"dc873611b5"},on:{"error":_vm.onError,"success":_vm.onSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var subscribe = ref.subscribe;
var setEmail = ref.setEmail;
var error = ref.error;
var success = ref.success;
var loading = ref.loading;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return subscribe($event)}}},[_c('v-responsive',{staticClass:"overflow-visible mr-2",attrs:{"max-width":"400"}},[_c('v-text-field',{staticStyle:{"border":"1px solid rgb(255, 255, 255, 0.6)","width":"300px"},attrs:{"hide-details":"","label":"Email","required":"","solo":""},on:{"input":function($event){return setEmail($event)}},model:{value:(_vm.emailSubscriber),callback:function ($$v) {_vm.emailSubscriber=$$v},expression:"emailSubscriber"}})],1),(_vm.emailSubscriber)?_c('v-btn',{staticClass:"ma-0 mr-2 mt-1",staticStyle:{"height":"48px","width":"300px"},attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"color":"primary","type":"submit"}},[_vm._v(" Sottoscrivi ")]):_vm._e(),(error)?_c('div',[_vm._v(_vm._s(error))]):_vm._e(),(success)?_c('div',[_vm._v("Success")]):_vm._e(),(loading)?_c('div',[_vm._v("Loading…")]):_vm._e()],1)]}}])})],1)],1)],1)],1),_c('v-snackbar',{attrs:{"color":"success","top":"","right":"","timeout":"2000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Success ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }