<template>
  <div id="mailchimp-subscribe" class="white--text mt-10">
    <v-row class="white--text">
      <v-col cols="12" class="mb-2 col-md-10 offset-md-1">
        <v-card
          class="d-flex flex-wrap px-2 mb-2"
          :class="subscribeStylingResponsive"
          outlined
          dark
        >
          <div class="">
            <v-card-title class="body-2"
              >Rimani aggiornato sulle novitá di Dear Mama</v-card-title
            >
            <v-card-subtitle class="caption"
              >No spam, solo notizie estremamente rilevanti.</v-card-subtitle
            >
          </div>
          <v-card-actions>
            <mailchimp-subscribe
              url="https://dearmamafilm.us1.list-manage.com/subscribe/post-json"
              user-id="8c358d4ae52a6ebf2de70ad84"
              list-id="dc873611b5"
              @error="onError"
              @success="onSuccess"
            >
              <template
                v-slot="{ subscribe, setEmail, error, success, loading }"
              >
                <form @submit.prevent="subscribe">
                  <v-responsive class="overflow-visible mr-2" max-width="400">
                    <v-text-field
                      hide-details
                      label="Email"
                      required
                      solo
                      v-model="emailSubscriber"
                      style="
                        border: 1px solid rgb(255, 255, 255, 0.6);
                        width: 300px;
                      "
                      @input="setEmail($event)"
                    />
                  </v-responsive>
                  <v-btn
                    :block="$vuetify.breakpoint.xsOnly"
                    class="ma-0 mr-2 mt-1"
                    color="primary"
                    style="height: 48px; width: 300px"
                    v-if="emailSubscriber"
                    type="submit"
                  >
                    Sottoscrivi
                  </v-btn>
                  <div v-if="error">{{ error }}</div>
                  <div v-if="success">Success</div>
                  <div v-if="loading">Loading…</div>
                </form>
              </template>
            </mailchimp-subscribe>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" color="success" top right timeout="2000">
      Success

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import MailchimpSubscribe from "vue-mailchimp-subscribe";

export default {
  components: {
    MailchimpSubscribe,
  },
  data() {
    return {
      emailSubscriber: null,
      snackbar: false,
      snackbarMessage: null,
      snackbarColor: "success",
    };
  },
  computed: {
    subscribeStylingResponsive() {
      // let a = "d-flex flex-wrap justify-space-between px-2 mb-2";
      // if (this.$vuetify.breakpoint.name == "xs") {
      //   a = "d-flex flex-wrap justify-center px-2 mb-2 text-center";
      // }
      let a = "justify-space-between";
      if (this.$vuetify.breakpoint.name == "xs") {
        a = "justify-center text-center";
      }
      return a;
    },
  },
  methods: {
    onError() {
      // handle error
      console.log("something bad happened");
    },
    onSuccess() {
      // handle success
      console.log("new subscriber added");
      this.emailSubscriber = null;
      this.snackbar = true;
      this.$gtag.query("event", "new_subscriber");
    },
  },
};
</script>